import { Button, Icon, Menu, MenuButton, MenuItem, MenuList, Spinner, Text } from '@chakra-ui/react'
import { HStack } from '@chakra-ui/layout'

import { KeyboardArrowDownIcon } from '../../components/icons'
import { useSearchWorkerPoolsQuery } from '../../redux/api/worker-pool'

export interface WorkerPoolPickerProps {
  value: number | null
  onChange: (value: number | null) => void
  disabled?: boolean
}

export function WorkerPoolPicker({ value, onChange, disabled = false }: WorkerPoolPickerProps) {
  const { isLoading, isSuccess, data } = useSearchWorkerPoolsQuery({})

  const selected = data?.results?.find((pool) => pool.worker_pool_id === value)

  return (
    <Menu>
      <MenuButton as={Button} variant="outline" colorScheme="gray" disabled={disabled} width="100%">
        <HStack overflowX="hidden">
          <Text
            as="span"
            flex="1"
            overflowX="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            textAlign="left"
            fontSize="md"
            fontWeight="semibold"
            margin="0"
          >
            {selected?.name || 'Default'}
          </Text>
          {!isLoading ? <Icon as={KeyboardArrowDownIcon} boxSize={6} /> : <Spinner boxSize={4} />}
        </HStack>
      </MenuButton>
      {isSuccess && !disabled && (
        <MenuList>
          <MenuItem onClick={() => onChange(null)}>Default</MenuItem>
          {data?.results?.map((pool) => (
            <MenuItem key={pool.worker_pool_id} onClick={() => onChange(pool.worker_pool_id)}>
              {pool.name}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Menu>
  )
}
