import { ComponentStyleConfig } from '@chakra-ui/react'

export const Table: ComponentStyleConfig = {
  variants: {
    simple: {
      borderBottom: 'none',
      th: {
        borderColor: 'inherit',
        textTransform: 'none',
        fontWeight: 'semibold',
        fontSize: 'sm'
      },
      td: {
        borderColor: 'inherit'
      },
      tr: {
        '&:last-of-type': {
          td: {
            borderBottom: '1px solid transparent' // removes annoying bottom border that was appearing on the last row
          }
        }
      }
    }
  }
}
