import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { cardAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

const cardBaseStyle = definePartsStyle({
  container: {
    background: 'body',
    borderWidth: '1px',
    borderRadius: '3xl',
    borderColor: 'inherit'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingX: '8',
    paddingTop: '2',
    paddingBottom: '2',
    borderBottom: '0.5px solid',
    borderTopLeftRadius: '2xl',
    borderTopRightRadius: '2xl',
    borderColor: 'inherit',
    minHeight: '14'
  },
  body: {
    padding: 0
  },
  footer: {
    borderTop: '0.5px solid',
    borderColor: 'inherit',
    padding: '4'
  }
})

const cardVariants = {
  base: {},
  info: definePartsStyle({
    container: {
      borderColor: 'accent'
    },
    header: {
      bg: 'accent',
      borderColor: 'accent',
      color: 'body'
    }
  }),
  warning: definePartsStyle({
    container: {
      borderColor: 'warning'
    },
    header: {
      bg: 'warning',
      borderColor: 'warning',
      color: 'body'
    }
  }),
  bad: definePartsStyle({
    container: {
      borderColor: 'bad'
    },
    header: {
      bg: 'bad',
      borderColor: 'bad',
      color: 'body'
    }
  })
}
export const Card = defineMultiStyleConfig({
  baseStyle: cardBaseStyle,
  variants: cardVariants,
  defaultProps: { variant: 'base' }
})
